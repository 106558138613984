<script setup lang="ts">
const { fullPath } = useRoute()
const { url } = useImageStorage()

const emits = defineEmits([
  'click:login',
])

const clickLogin = () => {
  useCookie(`${useRuntimeConfig().public.supabase.cookieName}-redirect-path`).value = fullPath
  navigateTo('/login')
  emits('click:login')
}
</script>

<template>
  <div class="w-full flex flex-col items-center gap-3.5">
    <NuxtImg
      class="w-36 cursor-pointer mb-0.5"
      :src="url(true, '/assets/logo/wnm_horizontal_logo.svg')"
      loading="eager"
    />
    <p class="text-base font-semibold text-gray05Light dark:text-gray05Dark">
      {{ $t('login.loginDescription') }}
    </p>
    <AButton
      custom-class="w-full h-12 font-semibold"
      button-block
      button-size="xl"
      button-color="green"
      button-variant="soft"
      button-confirm-color="primary"
      :button-text="$t('button.login')"
      @click="clickLogin"
    />
  </div>
</template>
